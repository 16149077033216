// styling
import styles from './style.module.scss';

// components
import Metadata from '@ui/Metadata';
import Avatar from '@ui/Avatar';
import SharePost from '@ui/SharePost';
import LazyImage from '@components/LazyImage';

// assets


const Copyright = () => {
    return (
        <div className="post-section">
            <div className={styles.header}>
                <div className={styles.header_author}>
                     Cradomint
                </div>
               
            </div>
            <article>
                <h3 className={styles.title}>COPYRIGHT POLICY</h3>
                
<h2 style={{textAlign: 'center'}}><b></b></h2>
<p>This Copyright Policy describes how Cradomint ("we," "us," or "our") protects intellectual property rights on our platform. We respect the intellectual property rights of others and expect our users to do the same.</p>
        
        <h2>Ownership of Content</h2>
        <p>All content, including but not limited to text, graphics, images, and software, on the Cradomint platform is the property of Cradomint or its content suppliers and protected by international copyright laws. The compilation of all content on this platform is the exclusive property of Cradomint and protected by international copyright laws.</p>
        
        <h2>Submitting a Copyright Complaint</h2>
        <p>If you believe that your work has been copied in a way that constitutes copyright infringement, please provide us with the following information:</p>
        <ul>
            <li>A physical or electronic signature of the person authorized to act on behalf of the owner of the copyright interest;</li>
            <li>A description of the copyrighted work that you claim has been infringed;</li>
            <li>A description of where the material that you claim is infringing is located on the site;</li>
            <li>Your address, telephone number, and email address;</li>
            <li>A statement by you that you have a good faith belief that the disputed use is not authorized by the copyright owner, its agent, or the law;</li>
            <li>A statement by you, made under penalty of perjury, that the above information in your notice is accurate and that you are the copyright owner or authorized to act on the copyright owner's behalf.</li>
        </ul>
        
        <h2>Counter-Notification</h2>
        <p>If you believe that your content that was removed (or to which access was disabled) is not infringing, or that you have the authorization from the copyright owner, the copyright owner's agent, or pursuant to the law, to post and use the content, you may send a counter-notification containing the following information to our copyright agent:</p>
        <ul>
            <li>Your physical or electronic signature;</li>
            <li>Identification of the content that has been removed or to which access has been disabled and the location at which the content appeared before it was removed or disabled;</li>
            <li>A statement that you have a good faith belief that the content was removed or disabled as a result of mistake or a misidentification of the content;</li>
            <li>Your name, address, telephone number, and email address;</li>
            <li>A statement that you consent to the jurisdiction of the federal court in the EU, and a statement that you will accept service of process from the person who provided notification of the alleged infringement.</li>
        </ul>
        
        <h2>Contact Information</h2>
        <p>If you have any questions about this Copyright Policy, please contact us at:</p>
        <p>Email: support@cradomint.com</p>
        

               
            </article>
        </div>
    )
}

export default Copyright